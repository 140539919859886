import Link from 'next/link';

const NotFoundPage = () => {
  return (
    <div className="container">
      <h1 className="title">404 - Page Not Found</h1>
      <p className="description">
        Oops! The page you&apos;re looking for doesn&apos;t exist.
      </p>
      <p className="description">
        You can{' '}
        <Link href="/">
          <a className="link">go back to the homepage</a>
        </Link>{' '}
        or try searching for what you need.
      </p>
      <style jsx>{`
        .container {
          background-color: #f4f4f4;
          font-family: Arial, sans-serif;
          text-align: center;
          padding: 40px;
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        .title {
          font-size: 36px;
          color: #333;
        }
        .description {
          font-size: 18px;
          color: #666;
          margin-bottom: 20px;
        }
        .link {
          color: #007bff;
          text-decoration: none;
        }
      `}</style>
    </div>
  );
};

export default NotFoundPage;
